<template>
    <div class="Article">
        <div class="text-center"><div class="figure" v-bind:style="[this.figureStyle]"></div></div>
        <div class="bg-white container pt-3 pb-5 mb-5">
            <h1>{{ this.article.title }}</h1>
            <h6>Posted by <strong>{{ this.article.author }}</strong> at <time datetime="2020-11-21">{{ this.article.date }}</time> </h6>
            <section v-html="this.article.body" ref="contenido"></section>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            monthsName:["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            figureStyle:{ background: 'white' },
            article:{
                title: 'Empty title',
                author: 'Author',
                body:'Contenido del articulo',
                date: 'November 11th'
            } 
        }
    },
    methods:{
        findArticle(id = null,mainArticle = false){
            const _query = !mainArticle ? "article/bw" : "articlebwfixed/listado";
            const _URL = String.prototype.concat(window.GLOBAL.URL_BRAVEWOLF_OFFICE_API,_query)
            let postdata = { id:id  }
            let _d = new FormData();
            
            if(id == null){
                this.$swal('Article not found',"Sorry but the article is not exist",'warning').then(()=>{
                    this.$router.go('/')
                });
                return false;
            }
            this.$swal.fire({
                title: 'Loading Article, please wait',
                background: '#ffffff',
                showConfirmButton: false,
                allowOutsideClick: false,
                willOpen: () => {
                    this.$swal.showLoading();
                }
            })

            Object.keys(postdata) .forEach(e=>{ _d.append(e.toString(),postdata[e]); });
            
            fetch(_URL,{
                method:'POST',
                body:_d
            })
            .then(r=>r.json())
            .then(response => {
                this.$swal.close();
                if(response.code == 200 && Object.values(response.data).length > 0){
                    const row = response.data[0]
                    let dateArticle = new Date(row.DateArticle)

                    this.article.title = row.Title
                    this.article.author = row.Author
                    this.article.body = row.Body
                    this.article.date = String.prototype.concat(this.monthsName[dateArticle.getMonth()]," ",dateArticle.getDate())
                    this.figureStyle.background = String.prototype.concat('url(',row.Img,')') 
                }else{
                     this.$swal('Article not found',"Sorry but the article is not exist",'warning').then(()=>{
                        this.$router.go('/')
                    });
                }
            })
        }
    },
    created(){
        const isMain = (this.$route.name == 'ViewArticleMain')
        this.findArticle(this.$route.params.id,isMain)
    }
}
</script>
<style>
.Article {  width:100%; padding-top:60px; }
.Article .figure { background-size: cover!important; background-repeat: no-repeat!important; background-position: center!important; width: 100%; height:80vh; max-height:500px; max-width: 1840px; margin: 0 auto; }
</style>